<template>
  <b-modal
    id="add-edit-manufacture"
    @hidden="$emit('resetModal')"
    :title="selectedManufacture ? 'Update Manufacture' : 'Add Manufacture'"
    hide-footer
  >
    <div v-if="selectedManufacture">
      <FormulateForm
        name="addEditManufacture"
        v-model="values"
        :schema="updateSchema"
        @submit="submitHandler"
      >
        <FormulateInput
          type="image"
          name="Image"
          label="Select an image to upload"
          help="Select a png, jpg or gif to upload."
        />
        <FormulateInput type="submit" class="float-right">
          <span v-if="performAction">
            <b-spinner class="vueformulate-loader"></b-spinner>
            <span v-if="selectedManufacture"> Updating... </span>
            <span v-else> Adding... </span>
          </span>
          <span v-else>
            <span v-if="selectedManufacture"> Update </span>
            <span v-else> Add </span>
            Manufacture</span
          >
        </FormulateInput>
      </FormulateForm>
    </div>
    <div v-else>
      <FormulateForm
        name="createManufacture"
        v-model="values"
        :schema="addSchema"
        @submit="submitHandler"
        ><FormulateInput
          type="image"
          name="Image"
          label="Select an image to upload"
          help="Select a png, jpg or gif to upload."
          validation="required:mime:image/jpeg,image/png,image/gif"
        />
        <FormulateInput type="submit" class="float-right">
          <span v-if="performAction">
            <b-spinner class="vueformulate-loader"></b-spinner>
            <span> Adding... </span>
          </span>
          <span v-else>Add Manufacture</span>
        </FormulateInput>
      </FormulateForm>
    </div>
  </b-modal>
</template>

<script>
// import { getObjFromArray } from "@/utils/getObjFromArray";
import { vehicle } from "@/config/api/vehicles";
export default {
  props: ["selectedManufacture"],
  data() {
    return {
      values: {},
      performAction: false,
      // _id:
      // name:
      // logo:
      // status:
      // createdAt:
      // deletedAt:
      // deletedBy:
      // updatedAt
      addSchema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
        {
          index: 1,
          type: "select",
          name: "statusText",
          label: "Status",
          options: ["Pending", "Confirmed"],
          validation: "required",
        },
        //Ask Ali how to make this an image selector
        // {
        //   index: 2,
        //   type: "password",
        //   name: "password",
        //   label: "Password",
        //   validation: "required",
        // },
      ],
      updateSchema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
        {
          index: 1,
          type: "select",
          name: "statusText",
          label: "Status",
          options: ["Pending", "Confirmed"],
          validation: "required",
        },
        //Ask Ali how to make this an image selector
        // {
        //   index: 2,
        //   type: "password",
        //   name: "password",
        //   label: "Password",
        //   validation: "required",
        // },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      if (data.statusText.toLowerCase() == "pending") {
        data.status = 0;
      } else {
        data.status = 1;
      }
      if (this.selectedManufacture) {
        if (data.Image) {
          data.logo = data.Image[0].location;
        }
        this.updateManufacture(data, this.selectedManufacture._id);
      } else {
        if (data.Image) {
          data.logo = data.Image[0].location;
        }
        this.addUser(data);
      }
    },
    addUser(data) {
      this.performAction = true;
      const api = vehicle.manufacture.create;
      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          // this.$formulate.reset("addEditManufacture");
          this.$bvToast.toast("Manufacture added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Manufacture is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateManufacture(data, id) {
      this.performAction = true;
      const api = vehicle.manufacture.update;
      api.data = data;
      api.id = id;
      //   console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
      //   console.log("Update Manufacture API");
      //   console.log(api);
      //   console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Manufacture updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Manufacture is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedManufacture(val) {
      console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
      console.log("SELECTED MANUFATURE CHANGED");
      console.log(val);
      console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
      if (val) {
        this.values = { ...val };

      } else {
        this.values = null;
      }
    },
  },
};
</script>

<style></style>
