var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":"Manufactures"}}),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('div',{staticClass:"dataTables_filter",attrs:{"id":"tickets-table_filter"}},[_c('label',{staticClass:"d-inline-flex align-items-center"},[_vm._v(" Search: "),_c('b-form-input',{staticClass:"form-control form-control-sm ml-2",attrs:{"type":"search","placeholder":"Search..."},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)])]),_c('div',{staticClass:"col-6 d-inline-flex justify-content-end"},[_c('b-button',{staticClass:"bseen-btn",on:{"click":function () {
            this$1.selectedManufacture = null;
            _vm.showActionModal();
          }}},[_vm._v("Add")])],1)]),(_vm.isBusy)?_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1):_c('div',{staticClass:"row"},_vm._l((_vm.manufactureData),function(data,index){return _c('div',{key:index,staticClass:"col-xl-2 col-md-3 col-sm-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-3"},[_c('div',{staticClass:"d-flex justify-content-between mb-2"},[_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('button',{staticClass:"action-btn",on:{"click":function($event){return _vm.deleteUser(data)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]),_c('button',{staticClass:"action-btn",on:{"click":function($event){return _vm.editManufacture(data)}}},[_c('i',{staticClass:"fas fa-edit"})])]),_c('div',{style:(data.status == 0
                  ? 'color:red;font-weight:900'
                  : 'color:green;font-weight:900')},[(data.status == 0)?_c('span',[_vm._v("Inactive")]):_c('span',[_vm._v("Active")])])]),_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"avatar-lg mb-2",attrs:{"src":data.logo,"alt":""}}),_c('div',{staticClass:"media-body"},[_c('h7',{staticClass:"text-truncate"},[_c('a',{staticClass:"text-dark font-weight-bold",attrs:{"href":"#"}},[_vm._v(_vm._s(data.name)+" ")])])],1)])])])])}),0),_c('UpdateManufactureModel',{attrs:{"userRoles":_vm.userRoles,"selectedManufacture":_vm.selectedManufacture},on:{"resetModal":function($event){_vm.selectedManufacture = null},"reloadData":_vm.getAllManufactures}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }