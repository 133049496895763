export const vehicle = {
  manufacture: {
    get: {
      url: "admin/vehicle_manufacture/get/",
      method: "GET",
      id: null,
      params: null,
    },
    create: {
      url: "admin/vehicle_manufacture/add/",
      method: "POST",
      data: null,
    },
    delete: {
      url: "admin/vehicle_manufacture/delete/",
      method: "DELETE",
      id: null,
    },
    update: {
      url: "admin/vehicle_manufacture/update/",
      method: "PUT",
      id: null,
      data: null,
    },
  },
  model: {
    create: {
      url: "admin/vehicle_model/add/",
      method: "POST",
      data: null,
    },
    update: {
      url: "admin/vehicle_model/update/",
      method: "PUT",
      id: null,
      data: null,
    },
    get: {
      url: "admin/vehicle_model/get/",
      method: "GET",
      id: null,
      params: null,
    },
    delete: {
      url: "admin/vehicle_model/delete/",
      method: "DELETE",
      id: null,
    },
  },
  vehicle:{
    get: {
      url: "vehicle/getVehicleInfoById/",
      method: "GET",
      id: null,
      params: null,
    },
  }
};
